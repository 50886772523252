import { motion } from 'framer-motion';
import './index.scss';

const BorderedText = ({
  text,
  backgroundColor,
  textColor,
  rotate,
  innerClassName,
  onClick = () => {},
  pulse = false,
}) => {
  return (
    <motion.div
      onClick={onClick}
      style={{ display: 'inline-block' }}
      initial={{
        rotate: Number(rotate) * 2,
        opacity: 0,
      }}
      animate={{
        rotate: Number(rotate),
        opacity: 1,
        transition: { duration: 0.5 },
      }}
      className={`${innerClassName} bordered-text`}
    >
      <span
        className={`styled-text ${pulse ? 'pulse' : ''}`}
        style={{
          color: textColor,
          background: backgroundColor,
        }}
      >
        {text}
      </span>
    </motion.div>
  );
};

export default BorderedText;
