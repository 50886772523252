import { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import MainBanner from 'components/Dashboard/MainBanner';

const Feature = lazy(() => import('../../components/Dashboard/Feature'));
const Info = lazy(() => import('../../components/Dashboard/Info'));
const Video = lazy(() => import('../../components/Dashboard/Video'));
const Work = lazy(() => import('../../components/Dashboard/Work'));
const MainFeaturesList = lazy(() => import('../../components/Dashboard/MainFeaturesList'));
const UserSaying = lazy(() => import('../../components/Dashboard/UserSaying'));
const TryEarkick = lazy(() => import('../../components/Dashboard/TryEarkick'));

const Dashboard = ({ canonical }) => {
  return (
    <Suspense fallback={<MainBanner />}>
      <div className="main-content flex-column" style={{ background: '#FFFEF5' }}>
        <Helmet>
          <link rel="canonical" href={ canonical } />
        </Helmet>
        <MainBanner />
        <Feature />
        <Info />
        <Video />
        <Work />
        <MainFeaturesList />
        <UserSaying />
        <TryEarkick />
      </div>
    </Suspense>
  );
};

export default Dashboard;
