import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import './styles/style.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Dashboard from "containers/Dashboard";
import ROUTERS from 'constants/Routers';
import LANDING_ROUTERS from 'constants/landing-routers';

import { Suspense, lazy, useEffect } from 'react';
import Loader from 'components/Loader';

const baseURI = process.env.REACT_APP_DOMAIN || 'http://localhost:3000'

const About = lazy(() => import('./containers/About'));
const Chat = lazy(() => import('./pages/chat'));
//const PandaPersonalityBigSelection = lazy(() => import('./pages/chat/panda-personality/big-selection'));
const ChatHello = lazy(() => import('./pages/chat/hello'));
const DemoResults = lazy(() => import('./components/TryNow/DemoResults'));
const Landing1 = lazy(() => import('./pages/landing/1'));
const Landing2 = lazy(() => import('./pages/landing/2'));
const LandingSelfCarejournal = lazy(() => import('./pages/landing/self-care-journal'));
const NotFound = lazy(() => import('./pages/404Page'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const Referrals = lazy(() => import('./pages/referrals'));
const PandaPersonasShare = lazy(() => import('./pages/panda-personas-share'));
const SharePage = lazy(() => import('./pages/Share'));
const TeamJoin = lazy(() => import('./pages/team-join'));
const Technologies = lazy(() => import('./containers/Technologies'));
const Terms = lazy(() => import('./components/TermsOfService'));
const TryNowDemo = lazy(() => import('./components/TryNow/Demo/index'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector("body").scrollTo(0,0)
  }, [pathname]);

  return null;
}

const DefaultLayout = ({ children }) => {
  return <>
    <Header />
    {children}
    <Footer />
  </>
}

const CenteredLoader = () => {
  return <div className="centered-loader">
    <center>
      <Loader />
    </center>
  </div>
}

const AppRouter = () => {
  return (
    <div className="router-style">
      <Router>
        <ScrollToTop />
        <Switch>

          <Route exact path={ROUTERS.TRY_NOW_DEMO}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <TryNowDemo canonical={`${baseURI}${ROUTERS.TRY_NOW_DEMO}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route exact path={ROUTERS.HOME}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <Dashboard canonical={`${baseURI}/`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route exact path={ROUTERS.ABOUT}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <About canonical={`${baseURI}${ROUTERS.ABOUT}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route path={ROUTERS.PRIVACYPOLICY}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <PrivacyPolicy canonical={`${baseURI}${ROUTERS.PRIVACYPOLICY}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route path={ROUTERS.PRIVACY}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <PrivacyPolicy canonical={`${baseURI}${ROUTERS.PRIVACYPOLICY}`} />
              </Suspense>
            </DefaultLayout>
          </Route>
          
          <Route path={ROUTERS.SHARE_NEW}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <SharePage canonical={`${baseURI}${ROUTERS.SHARE_NEW}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route path={ROUTERS.SHARE}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <SharePage canonical={`${baseURI}${ROUTERS.SHARE}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route exact path={ROUTERS.TECHNOLOGIES}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <Technologies canonical={`${baseURI}${ROUTERS.TECHNOLOGIES}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route exact path={ROUTERS.TERMS}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <Terms canonical={`${baseURI}${ROUTERS.TERMS}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route exact path={ROUTERS.TEAM_JOIN}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <TeamJoin canonical={`${baseURI}${ROUTERS.TEAM_JOIN}`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route
            exact
            path={ROUTERS.TRY_NOW_DEMO_RESULTS}>
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <DemoResults canonical={`${baseURI}${ROUTERS.TRY_NOW_DEMO_RESULTS}`} />
              </Suspense>
            </DefaultLayout>
          </Route>
  
          <Route exact path="/r">
            <Suspense fallback={<CenteredLoader />}>
              <Referrals canonical={`${baseURI}r`} />
            </Suspense>
          </Route>
          <Route exact path={ROUTERS.REFERRAL}>
            <Suspense fallback={<DefaultLayout>Loading...</DefaultLayout>}>
              <Referrals canonical={`${baseURI}${ROUTERS.REFERRAL}`} />
            </Suspense>
          </Route>          
          
          <Route exact path={ROUTERS.PANDA_PERSONAS_SHARE}>
            <Suspense fallback={<DefaultLayout>Loading...</DefaultLayout>}>
              <PandaPersonasShare canonical={`${baseURI}${ROUTERS.PANDA_PERSONAS_SHARE}`} />
            </Suspense>
          </Route>

          { /*
          <Route exact path={ROUTERS.CHAT_PANDA_SELECTION}>
            <Suspense fallback={<CenteredLoader />}>
              <PandaPersonalityBigSelection canonical={`${baseURI}${ROUTERS.CHAT_PANDA_SELECTION}`}/>
            </Suspense>
          </Route>
          */ }

          <Route exact path={ROUTERS.CHAT_PANDA_HELLO}>
            <Suspense fallback={<CenteredLoader />}>
              <ChatHello canonical={`${baseURI}${ROUTERS.CHAT_PANDA_HELLO}`}/>
            </Suspense>
          </Route>

          <Route exact path={ROUTERS.CHAT}>
            <Suspense fallback={<CenteredLoader />}>
              <Chat canonical={`${baseURI}${ROUTERS.CHAT}`}/>
            </Suspense>
          </Route>

          <Route exact path={LANDING_ROUTERS.SELFCAREJOURNAL}>
            <Suspense fallback={<CenteredLoader />}>
              <LandingSelfCarejournal canonical={LANDING_ROUTERS.SELFCAREJOURNAL}/>
            </Suspense>
          </Route>

          <Route exact path={LANDING_ROUTERS.LANDING2}>
            <Suspense fallback={<CenteredLoader />}>
              <Landing2 canonical={LANDING_ROUTERS.LANDING2}/>
            </Suspense>
          </Route>

          <Route exact path={LANDING_ROUTERS.LANDING1}>
            <Suspense fallback={<CenteredLoader />}>
              <Landing1 canonical={LANDING_ROUTERS.LANDING1}/>
            </Suspense>
          </Route>

          <Route exact path="/404-not-found">
            <DefaultLayout>
              <Suspense fallback={<CenteredLoader />}>
                <NotFound canonical={`${baseURI}404-not-found`} />
              </Suspense>
            </DefaultLayout>
          </Route>

          <Route path="*">
            <Redirect to="/404-not-found" />
          </Route>

        </Switch>
      </Router>
    </div>
  );
};

export default AppRouter;
