import { createSlice } from '@reduxjs/toolkit'

export const resultsProfiler = createSlice({
  name: 'resultsProfiler',
  initialState: {
    value: 0,
    resultsId: false,
    startTime: false
  },
  reducers: {
    setResultsId: (state, value) => {
      state.resultsId = value.payload
    },
    setStartTime: (state, value) => {
      state.startTime = value.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setResultsId, setStartTime } = resultsProfiler.actions

export default resultsProfiler.reducer